'use strict';

angular.module('enervexSalesappApp')
.config(function ($stateProvider) {
	$stateProvider
	.state('plume-coilhands', {
		url: '/plume-coilhands',
		templateUrl: 'app/admin/plume-coilhands/plume-coilhands.html',
		controller: 'PlumeCoilhandsCtrl',
		authenticate: true
	})
	.state('plume-coilhand', {
		url: '/plume-coilhands/:id',
		templateUrl: 'app/admin/plume-coilhands/plume-coilhand.html',
		controller: 'PlumeCoilhandCtrl',
		authenticate: true
	})
	.state('plume-headers', {
		url: '/plume-headers',
		templateUrl: 'app/admin/plume-headers/plume-headers.html',
		controller: 'PlumeHeadersCtrl',
		authenticate: true
	})
	.state('plume-header', {
		url: '/plume-headers/:id',
		templateUrl: 'app/admin/plume-headers/plume-header.html',
		controller: 'PlumeHeaderCtrl',
		authenticate: true
	})
	.state('savings-goals', {
		url: '/savings-goals',
		templateUrl: 'app/admin/savings-goals/savings-goals.html',
		controller: 'SavingsGoalsCtrl',
		authenticate: true
	})
	.state('savings-goal', {
		url: '/savings-goals/:id',
		templateUrl: 'app/admin/savings-goals/savings-goal.html',
		controller: 'SavingsGoalCtrl',
		authenticate: true
	})
	.state('lineitem-sources', {
		url: '/lineitem-sources',
		templateUrl: 'app/admin/lineitem-sources/lineitem-sources.html',
		controller: 'LineitemSourcesCtrl',
		authenticate: true
	})
	.state('lineitem-source', {
		url: '/lineitem-sources/:id',
		templateUrl: 'app/admin/lineitem-sources/lineitem-source.html',
		controller: 'LineitemSourceCtrl',
		authenticate: true
	})
	.state('insulation-types', {
		url: '/insulation-types',
		templateUrl: 'app/admin/insulation-types/insulation-types.html',
		controller: 'InsulationTypesCtrl',
		authenticate: true
	})
	.state('insulation-type', {
		url: '/insulation-types/:id',
		templateUrl: 'app/admin/insulation-types/insulation-type.html',
		controller: 'InsulationTypeCtrl',
		authenticate: true
	})
	.state('metal-types', {
		url: '/metal-types',
		templateUrl: 'app/admin/metal-types/metal-types.html',
		controller: 'MetalTypesCtrl',
		authenticate: true
	})
	.state('metal-type', {
		url: '/metal-types/:id',
		templateUrl: 'app/admin/metal-types/metal-type.html',
		controller: 'MetalTypeCtrl',
		authenticate: true
	})
	.state('location-datas', {
		url: '/location-datas',
		templateUrl: 'app/admin/location-datas/location-datas.html',
		controller: 'LocationDatasCtrl',
		authenticate: true
	})
	.state('location-data', {
		url: '/location-datas/:id',
		templateUrl: 'app/admin/location-datas/location-data.html',
		controller: 'LocationDataCtrl',
		authenticate: true
	})
	.state('users', {
		url: '/users',
		templateUrl: 'app/admin/users/users.html',
		controller: 'UsersCtrl',
		authenticate: true
	})
	.state('user', {
		url: '/users/:id',
		templateUrl: 'app/admin/users/user.html',
		controller: 'UserCtrl',
		authenticate: true
	})
	.state('manufacturers', {
		url: '/manufacturers',
		templateUrl: 'app/admin/manufacturers/manufacturers.html',
		controller: 'ManufacturersCtrl',
		authenticate: true
	})
	.state('manufacturer', {
		url: '/manufacturers/:id',
		templateUrl: 'app/admin/manufacturers/manufacturer.html',
		controller: 'ManufacturerCtrl',
		authenticate: true
	})
	.state('customer-contacts', {
		url: '/customer-companies/:companyId/customer-contacts',
		templateUrl: 'app/admin/customer-contacts/customer-contacts.html',
		controller: 'CustomerContactsCtrl',
		authenticate: true
	})
	.state('customer-contact', {
		url: '/customer-companies/:companyId/customer-contacts/:id',
		templateUrl: 'app/admin/customer-contacts/customer-contact.html',
		controller: 'CustomerContactCtrl',
		authenticate: true
	})
	.state('diameters', {
		url: '/diameters',
		templateUrl: 'app/admin/diameters/diameters.html',
		controller: 'DiametersCtrl',
		authenticate: true
	})
	.state('diameter', {
		url: '/diameters/:id',
		templateUrl: 'app/admin/diameters/diameter.html',
		controller: 'DiameterCtrl',
		authenticate: true
	})
	.state('system-tasks', {
		url: '/system-tasks',
		templateUrl: 'app/admin/system-tasks/system-tasks.html',
		controller: 'SystemTasksCtrl',
		authenticate: true
	})
	.state('system-task', {
		url: '/system-tasks/:id',
		templateUrl: 'app/admin/system-tasks/system-task.html',
		controller: 'SystemTaskCtrl',
		authenticate: true
	})
	.state('customer-companies', {
		url: '/customer-companies',
		templateUrl: 'app/admin/customer-companies/customer-companies.html',
		controller: 'CustomerCompaniesCtrl',
		authenticate: true
	})
	.state('customer-company', {
		url: '/customer-companies/:id',
		templateUrl: 'app/admin/customer-companies/customer-company.html',
		controller: 'CustomerCompanyCtrl',
		authenticate: true
	})
	.state('properties', {
		url: '/properties',
		templateUrl: 'app/admin/properties/properties.html',
		controller: 'PropertiesCtrl',
		authenticate: true
	})
	.state('property', {
		url: '/properties/:id',
		templateUrl: 'app/admin/properties/property.html',
		controller: 'PropertyCtrl',
		authenticate: true
	})
	.state('baffle-types', {
		url: '/baffle-types',
		templateUrl: 'app/admin/baffle-types/baffle-types.html',
		controller: 'BaffleTypesCtrl',
		authenticate: true
	})
	.state('baffle-type', {
		url: '/baffle-types/:id',
		templateUrl: 'app/admin/baffle-types/baffle-type.html',
		controller: 'BaffleTypeCtrl',
		authenticate: true
	})
	.state('residential-company-types', {
		url: '/residential-company-types',
		templateUrl: 'app/admin/residential-company-types/residential-company-types.html',
		controller: 'ResidentialCompanyTypesCtrl',
		authenticate: true
	})
	.state('residential-company-type', {
		url: '/residential-company-types/:id',
		templateUrl: 'app/admin/residential-company-types/residential-company-type.html',
		controller: 'ResidentialCompanyTypeCtrl',
		authenticate: true
	})
	.state('job-statuses', {
		url: '/job-statuses',
		templateUrl: 'app/admin/job-statuses/job-statuses.html',
		controller: 'JobStatusesCtrl',
		authenticate: true
	})
	.state('job-status', {
		url: '/job-statuses/:id',
		templateUrl: 'app/admin/job-statuses/job-status.html',
		controller: 'JobStatusCtrl',
		authenticate: true
	})
	.state('baffles', {
		url: '/baffles',
		templateUrl: 'app/admin/baffles/baffles.html',
		controller: 'BafflesCtrl',
		authenticate: true
	})
	.state('baffle', {
		url: '/baffles/:id',
		templateUrl: 'app/admin/baffles/baffle.html',
		controller: 'BaffleCtrl',
		authenticate: true
	})
	.state('mod-types', {
		url: '/mod-types',
		templateUrl: 'app/admin/mod-types/mod-types.html',
		controller: 'ModTypesCtrl',
		authenticate: true
	})
	.state('mod-type', {
		url: '/mod-types/:id',
		templateUrl: 'app/admin/mod-types/mod-type.html',
		controller: 'ModTypeCtrl',
		authenticate: true
	})
	.state('templates', {
		url: '/templates',
		templateUrl: 'app/admin/templates/templates.html',
		controller: 'TemplatesCtrl',
		authenticate: true
	})
	.state('template', {
		url: '/templates/:id',
		templateUrl: 'app/admin/templates/template.html',
		controller: 'TemplateCtrl',
		authenticate: true
	})
	.state('bom-rules', {
		url: '/bom-rules',
		templateUrl: 'app/admin/bom-rules/bom-rules.html',
		controller: 'BomRulesCtrl'
	})
	.state('bom-rule', {
		url: '/bom-rules/:id?copyId',
		templateUrl: 'app/admin/bom-rules/bom-rule.html',
		controller: 'BomRuleCtrl'
	})
	.state('fan-phases', {
		url: '/fan-phases',
		templateUrl: 'app/admin/fan-phases/fan-phases.html',
		controller: 'FanPhasesCtrl'
	})
	.state('fan-phase', {
		url: '/fan-phases/:id',
		templateUrl: 'app/admin/fan-phases/fan-phase.html',
		controller: 'FanPhaseCtrl'
	})
	.state('fan-voltages', {
		url: '/fan-voltages',
		templateUrl: 'app/admin/fan-voltages/fan-voltages.html',
		controller: 'FanVoltagesCtrl'
	})
	.state('fan-voltage', {
		url: '/fan-voltages/:id',
		templateUrl: 'app/admin/fan-voltages/fan-voltage.html',
		controller: 'FanVoltageCtrl'
	})
	.state('baffle-voltages', {
		url: '/baffle-voltages',
		templateUrl: 'app/admin/baffle-voltages/baffle-voltages.html',
		controller: 'BaffleVoltagesCtrl'
	})
	.state('baffle-voltage', {
		url: '/baffle-voltages/:id',
		templateUrl: 'app/admin/baffle-voltages/baffle-voltage.html',
		controller: 'BaffleVoltageCtrl'
	})
	.state('fans', {
		url: '/fans',
		templateUrl: 'app/admin/fans/fans.html',
		controller: 'FansCtrl'
	})
	.state('fan', {
		url: '/fans/:id?copyId',
		templateUrl: 'app/admin/fans/fan.html',
		controller: 'FanCtrl'
	})
	.state('controls', {
		url: '/controls',
		templateUrl: 'app/admin/controls/controls.html',
		controller: 'ControlsCtrl'
	})
	.state('control', {
		url: '/controls/:id',
		templateUrl: 'app/admin/controls/control.html',
		controller: 'ControlCtrl'
	})
	.state('applications', {
		url: '/applications',
		templateUrl: 'app/admin/applications/applications.html',
		controller: 'ApplicationsCtrl'
	})
	.state('application', {
		url: '/applications/:id',
		templateUrl: 'app/admin/applications/application.html',
		controller: 'ApplicationCtrl'
	})
	.state('fuels', {
		url: '/fuels',
		templateUrl: 'app/admin/fuels/fuels.html',
		controller: 'FuelsCtrl'
	})
	.state('fuel', {
		url: '/fuels/:id',
		templateUrl: 'app/admin/fuels/fuel.html',
		controller: 'FuelCtrl'
	})
	.state('vent-materials', {
		url: '/vent-materials',
		templateUrl: 'app/admin/vent-materials/vent-materials.html',
		controller: 'VentMaterialsCtrl'
	})
	.state('vent-material', {
		url: '/vent-materials/:id',
		templateUrl: 'app/admin/vent-materials/vent-material.html',
		controller: 'VentMaterialCtrl'
	})
	.state('cache', {
		url: '/cache',
		templateUrl: 'app/admin/cache/cache.html',
		controller: 'CacheCtrl'
	})
	.state('appliance-categories', {
		url: '/appliance-categories',
		templateUrl: 'app/admin/appliance-categories/appliance-categories.html',
		controller: 'ApplianceCategoriesCtrl'
	})
	.state('appliance-category', {
		url: '/appliance-categories/:id',
		templateUrl: 'app/admin/appliance-categories/appliance-category.html',
		controller: 'ApplianceCategoryCtrl'
	})
	.state('appliance-models', {
		url: '/appliance-companies/:applianceCompanyId/appliance-models',
		templateUrl: 'app/admin/appliance-models/appliance-models.html',
		controller: 'ApplianceModelsCtrl'
	})
	.state('appliance-model', {
		url: '/appliance-companies/:applianceCompanyId/appliance-models/:id',
		templateUrl: 'app/admin/appliance-models/appliance-model.html',
		controller: 'ApplianceModelCtrl'
	})
	.state('appliance-companies', {
		url: '/appliance-companies',
		templateUrl: 'app/admin/appliance-companies/appliance-companies.html',
		controller: 'ApplianceCompaniesCtrl'
	})
	.state('appliance-company', {
		url: '/appliance-companies/:id',
		templateUrl: 'app/admin/appliance-companies/appliance-company.html',
		controller: 'ApplianceCompanyCtrl'
	})
	.state('appliance-utility', {
		url: '/appliance-utility',
		templateUrl: 'app/admin/appliances/appliance-utility.html',
		controller: 'ApplianceUtilityCtrl'
	})
	.state('fittings', {
		url: '/fittings',
		templateUrl: 'app/admin/fittings/fittings.html',
		controller: 'FittingsCtrl'
	})
	.state('fitting', {
		url: '/fittings/:id?copyId',
		templateUrl: 'app/admin/fittings/fitting.html',
		controller: 'FittingCtrl'
	})
	.state('icons', {
		url: '/icons',
		templateUrl: 'app/admin/icons/icons.html',
		controller: 'IconsCtrl'
	})
	.state('icon', {
		url: '/icons/:id',
		templateUrl: 'app/admin/icons/icon.html',
		controller: 'IconCtrl'
	})
	.state('voltages', {
		url: '/voltages',
		templateUrl: 'app/admin/voltages/voltages.html',
		controller: 'VoltagesCtrl'
	})
	.state('voltage', {
		url: '/voltages/:id',
		templateUrl: 'app/admin/voltages/voltage.html',
		controller: 'VoltageCtrl'
	})

	// .state('accessory-types', {
	// 	url: '/accessory-types',
	// 	templateUrl: 'app/admin/accessory-types/accessory-types.html',
	// 	controller: 'AccessoryTypesCtrl'
	// })
	// .state('accessory-type', {
	// 	url: '/accessory-types/:id',
	// 	templateUrl: 'app/admin/accessory-types/accessory-type.html',
	// 	controller: 'AccessoryTypeCtrl'
	// })
	.state('stack-rule-phases', {
		url: '/stack-rule-phases',
		templateUrl: 'app/admin/stack-rule-phases/stack-rule-phases.html',
		controller: 'StackRulePhasesCtrl'
	})
	.state('stack-rule-phase', {
		url: '/stack-rule-phases/:id',
		templateUrl: 'app/admin/stack-rule-phases/stack-rule-phase.html',
		controller: 'StackRulePhaseCtrl'
	})
	.state('stack-rules', {
		url: '/stack-rules',
		templateUrl: 'app/admin/stack-rules/stack-rules.html',
		controller: 'StackRulesCtrl'
	})
	.state('stack-rule', {
		url: '/stack-rules/:id',
		templateUrl: 'app/admin/stack-rules/stack-rule.html',
		controller: 'StackRuleCtrl'
	})
	.state('product-subtypes', {
		url: '/product-subtypes',
		templateUrl: 'app/admin/product-subtypes/product-subtypes.html',
		controller: 'ProductSubtypesCtrl'
	})
	.state('product-subtype', {
		url: '/product-subtypes/:id',
		templateUrl: 'app/admin/product-subtypes/product-subtype.html',
		controller: 'ProductSubtypeCtrl'
	})
	.state('product-types', {
		url: '/product-types',
		templateUrl: 'app/admin/product-types/product-types.html',
		controller: 'ProductTypesCtrl'
	})
	.state('product-type', {
		url: '/product-types/:id',
		templateUrl: 'app/admin/product-types/product-type.html',
		controller: 'ProductTypeCtrl'
	})
	.state('application-subtypes', {
		url: '/application-subtypes',
		templateUrl: 'app/admin/application-subtypes/application-subtypes.html',
		controller: 'ApplicationSubtypesCtrl'
	})
	.state('application-subtype', {
		url: '/application-subtypes/:id',
		templateUrl: 'app/admin/application-subtypes/application-subtype.html',
		controller: 'ApplicationSubtypeCtrl'
	})
	.state('proposal-tables', {
		url: '/proposal-tables',
		templateUrl: 'app/admin/proposal-tables/proposal-tables.html',
		controller: 'ProposalTablesCtrl'
	})
	.state('proposal-table', {
		url: '/proposal-tables/:id',
		templateUrl: 'app/admin/proposal-tables/proposal-table.html',
		controller: 'ProposalTableCtrl'
	})
	;
});
